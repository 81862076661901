<template>
  <div>
    <div class="mloyalty-panel-burger-header">
      <a
        href="#"
        class="ml-title-14-20 d-flex align-items-center ml-text-grey"
        @click.prevent="$router.push('/confirming')"
      >
        <img src="@/assets/img/arrow-left.png" alt="" class="mr-2" />
        Назад
      </a>
      <div class="ml-text-16-24-600 flex-grow-1 text-center">Оплата</div>
    </div>
    <!-- <MlLoading v-if="loading" class="mt-16" /> -->
    <div id="payment-form" class="px-3 mt-2"></div>
    <section v-if="yookassaErrorInit">
      <v-alert color="red" dark>
        <v-icon>mdi-alert-circle</v-icon>
        Ошибка при инициализации ЮКассы
      </v-alert>
    </section>
    <!-- <div class="ml-back-btn-opacity" ref="panel" style="opacity: 0">
      <button
        class="w100 ml-black-btn m-auto"
        style="max-width: 460px"
        @click="$router.push('/confirming')"
      >
        <v-icon>mdi-arrow-left</v-icon>
        Назад
      </button>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import orderTypes from '@/store/order/types'
import { format } from 'date-fns'
export default {
  data: () => ({
    yookassaErrorInit: false,
    loading: false
  }),
  computed: {
    ...mapState({
      payment: state => state.yookassa.payment,
      appCode: state => state.app.code
    }),
    ...mapGetters('app', ['getSessionId'])
  },
  methods: {
    ...mapActions('order', [orderTypes.CREATE_OR_UPDATE_ORDER_ACTION]),
    async loadYookassaScript() {
      return new Promise(resolve => {
        let yookassaScript = document.createElement('script')
        yookassaScript.setAttribute(
          'src',
          'https://yookassa.ru/checkout-widget/v1/checkout-widget.js'
        )
        document.head.appendChild(yookassaScript)
        resolve()
      })
    },
    async loadYookassa() {
      this.loading = true
      const widgetUrl = process.env.VUE_APP_WIDGET_URL
      const errorCallback = this.yookassaErrors
      const paymentId = this.payment?.PaymentId
      const token = this.payment?.ConfirmationToken
      const returnUrl = this.payment?.ReturnUrl
      const sessionId = this.getSessionId
      const code = this.appCode
      const url = `${widgetUrl}/yookassa-completion-payment/?code=${code}&paymentId=${paymentId}&returnUrl=${returnUrl}&sessionId=${sessionId}`
      if (
        !widgetUrl ||
        !errorCallback ||
        !paymentId ||
        !sessionId ||
        !token ||
        !code ||
        !url
      ) {
        this.yookassaErrorInit = true
        console.log('Yookassa Error Init')
        this.showErrorYookassa()
      }
      try {
        const checkout = new window.YooMoneyCheckoutWidget({
          confirmation_token: token,
          return_url: url,
          embedded_3ds: true,
          error_callback: errorCallback
        })
        console.log('await form')
        await checkout.render('payment-form')
        console.log('await api order')
        await this[orderTypes.CREATE_OR_UPDATE_ORDER_ACTION]()
        this.loading = false
      } catch (e) {
        console.log('YOOKASSA TRY ERROR', e)
        this.$router.push({ name: 'ErrorPaymentYookassa' })
      }
      // .catch(error => {
      //   console.log('ERROR', error)
      // })
    },
    yookassaErrors(errors) {
      this.loading = false
      //TODO реализовать обработку ошибок, когда появится серверная часть
      console.log('yookassaErrors', errors)
      this.showErrorYookassa()
    },
    showErrorYookassa() {
      this.$router.push({ name: 'ErrorPaymentYookassa' })
    }
  },
  async mounted() {
    // console.log(new Date().getTime())
    console.log()
    await this.loadYookassaScript()
    setTimeout(async () => {
      await this.loadYookassa()
    }, 1000)
    // this.showBackBtn()
    window?.xprops?.onHide() // скрываем кнопку close у внешней sidePanel
    // console.log(this)
    // this.$loadScript(
    //   'https://yookassa.ru/checkout-widget/v1/checkout-widget.js'
    // ).then(x => {
    //   console.log('SUC', x)
    // })
  }
}
</script>
